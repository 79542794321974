.grid-list {
    display: grid;

    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    gap: 0px;
    height: 100%;
    grid-template-areas:
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . .";
}

.grid-item {
    background: #fefe;
}

@media (max-width: 768px) {
    .flex {
        display: grid;
        justify-items: center;
        gap: 5px;
        grid-template-columns: 50% 50%;
    }

    .flex .dashboard-card {
        width: 180px !important;
    }

    .center > div {
        width: 100% !important;
    }

    .ant-grid {
        display: grid;
        margin: 0 !important;
        justify-items: center;
        grid-template-columns: 50% 50%;
    }

    .ant-grid div {
        min-width: 100% !important;
        min-height: 140px !important;
    }
}
